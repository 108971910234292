import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { IGFLWeapon } from '../../common/model/graphql-types';

interface IProps {
  weapon: IGFLWeapon;
}

export const GFLWeaponInline: React.FC<IProps> = ({ weapon }) => {
  const image = weapon.image
    ? getImage(weapon.image.localFile.childImageSharp)
    : null;
  return (
    <div className={`inline-weapon-gfl rarity-${weapon.rarity}`}>
      <span className={`avatar gfl rarity-${weapon.rarity}`}>
        {image && (
          <GatsbyImage
            className="disable-transition"
            image={image}
            alt={`${weapon.name}`}
          />
        )}
      </span>
      <span className="inline-name">{weapon.name}</span>
    </div>
  );
};
