import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { IGFLWeapon } from '../../common/model/graphql-types';
import { GFLWeaponBox } from './exilium-weapon-db-box';
import { GFLWeaponInline } from './exilium-weapon-inline';

interface IProps {
  id?: string;
  weaponId?: string;
  name?: string;
  mode?: string;
}

export const GFLWeaponDB: React.FC<IProps> = ({ id, weaponId, name, mode }) => {
  const allWeapons = useStaticQuery(graphql`
    query {
      allContentfulGflWeapon {
        nodes {
          ...GflWeaponFieldsFragment
        }
      }
    }
  `);
  if ((!id && !weaponId && !name) || !mode) {
    return <div>Err</div>;
  }

  const weapon = allWeapons.allContentfulGflWeapon.nodes.find(
    (emp: IGFLWeapon) => (id ? emp.id === id : name && emp.name === name)
  );

  if (!weapon) {
    return <div>Err</div>;
  }

  return (
    <>
      {mode === 'box' && <GFLWeaponBox weapon={weapon} />}
      {mode === 'inline' && <GFLWeaponInline weapon={weapon} />}
    </>
  );
};
